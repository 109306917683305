var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"cardx",attrs:{"actionable":"","title":"Delays(Hot Pockets)"}},[_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('datepicker',{staticStyle:{"z-index":"1000"},attrs:{"placeholder":"Select Date"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[(_vm.getrole('cm4'))?_c('v-select',{staticStyle:{"width":"240px","z-index":"1000"},attrs:{"label":"cities","options":_vm.cities},model:{value:(_vm.selected_city),callback:function ($$v) {_vm.selected_city=$$v},expression:"selected_city"}}):_vm._e(),(_vm.getrole('cm1') || _vm.getrole('ac1') || _vm.getrole('exed'))?_c('v-select',{staticStyle:{"width":"240px","z-index":"1000"},attrs:{"disabled":"","label":"cities","options":_vm.cities},model:{value:(_vm.selected_city),callback:function ($$v) {_vm.selected_city=$$v},expression:"selected_city"}}):_vm._e()],1)],1),_c('vs-table',{staticStyle:{"margin-top":"3%","border-top":"1px solid","border-left":"1px solid","color":"black"},attrs:{"data":_vm.tableRows},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,style:(tr.style)},[_c('vs-td',[_vm._v(_vm._s(_vm.getName(tr.level)))]),_c('vs-td',{style:([_vm.citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ])},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  _vm.selected_city,
                  'allocated'
                )}}},[_vm._v("\n              "+_vm._s(tr.city.allocated)+"\n            ")])]),_c('vs-td',{style:([_vm.citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ])},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(tr.level, _vm.selected_city, 'delays')}}},[_vm._v("\n              "+_vm._s(tr.city.delays)+"\n            ")])]),_c('vs-td',{style:([_vm.citySelection === 'Pan India' ? {'border': 'none', 'background': indextr > 0 ? '#FFFF' : ''} : {'border': '1px solid', 'background': indextr > 0 ? '#FFCCCC' : ''} ])},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  _vm.selected_city,
                  'mhp'
                )}}},[_vm._v("\n              "+_vm._s(tr.city.mhp)+"\n            ")])]),_vm._l((tr.dynamicSpoc),function(spoc,spocIndex){return _c('vs-td',{key:spocIndex,style:({
              background: Math.abs(spocIndex % 3) == 2 && indextr > 0 ? '#FFCCCC' : '',
              border: '1px solid',
            })},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(
                  tr.level,
                  [tr, spocIndex],
                  data[0].dynamicSpoc[spocIndex]
                )}}},[_vm._v("\n              "+_vm._s(spoc)+"\n            ")])])})],2)})}}])},[_c('template',{slot:"thead"},_vm._l((_vm.tableHeaders),function(head,index){return _c('vs-th',{key:index,style:({ borderRight: '1px solid', fontSize: '13px'}),attrs:{"colspan":head.colspan}},[_vm._v(_vm._s(head.header))])}),1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }