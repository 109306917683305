<template>
  <div>
    <vx-card actionable class="cardx" title="Delays(Hot Pockets)">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <!-- <VueHotelDatepicker @confirm="getValue" /> -->
          <!-- <date-range-picker
          ref="picker"
          :opens="'right'"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
          :minDate="null"
          :maxDate="null"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="dateRange"
          style="width: 240px"
          @update="updateValues"
          ></date-range-picker>-->
          <datepicker
            placeholder="Select Date"
            v-model="dateRange"
            style="z-index: 1000"
          ></datepicker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <!-- <v-select
          label="cities"
          :options="cities"
          v-model="selected_city"
          style="width: 240px; z-index: 1000"
          ></v-select>-->
          <v-select
            v-if="getrole('cm4')"
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
          <v-select
            v-if="getrole('cm1') || getrole('ac1') || getrole('exed')"
            disabled
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>A -</b> Allocated
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>C -</b> Contacted
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>NC -</b> Not Contacted
        </vs-col>-->
      </vs-row>
      <vs-table
        :data="tableRows"
        style="
          margin-top: 3%;
          border-top: 1px solid;
          border-left: 1px solid;
          color: black;
        "
      >
        <template slot="thead">
          <vs-th
            :key="index"
            v-for="(head, index) in tableHeaders"
            :colspan="head.colspan"
            :style="{ borderRight: '1px solid', fontSize: '13px'}"
            >{{ head.header }}</vs-th
          >
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style">
            <vs-td>{{ getName(tr.level) }}</vs-td>
            <vs-td :style="[citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div
                @click="
                  preFlightFetch(
                    tr.level === '' ? tr.overall : tr.level,
                    selected_city,
                    'allocated'
                  )
                "
              >
                {{ tr.city.allocated }}
              </div>
            </vs-td>
            <vs-td :style="[citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="preFlightFetch(tr.level, selected_city, 'delays')">
                {{ tr.city.delays }}
              </div>
            </vs-td>
            <vs-td
              :style="[citySelection === 'Pan India' ? {'border': 'none', 'background': indextr > 0 ? '#FFFF' : ''} : {'border': '1px solid', 'background': indextr > 0 ? '#FFCCCC' : ''} ]"
            >
              <div
                @click="
                  preFlightFetch(
                    tr.level === '' ? tr.overall : tr.level,
                    selected_city,
                    'mhp'
                  )
                "
              >
                {{ tr.city.mhp }}
              </div>
            </vs-td>
            <vs-td
              v-for="(spoc, spocIndex) in tr.dynamicSpoc"
              :key="spocIndex"
              :style="{
                background: Math.abs(spocIndex % 3) == 2 && indextr > 0 ? '#FFCCCC' : '',
                border: '1px solid',
              }"
            >
              <div
                @click="
                  preFlightFetch(
                    tr.level,
                    [tr, spocIndex],
                    data[0].dynamicSpoc[spocIndex]
                  )
                "
              >
                {{ spoc }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import VueApexCharts from "vue-apexcharts";
import EventBus from "../components/eventbus";
export default {
  data() {
    return {
      citySelection: "",
      popupString: "",
      pieChart: {
        series: [],
        chartOptions: {
          legend: {
            show: false,
          },
          labels: [],
          colors: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      name: "App",
      type: "margin",
      fromDate: null,
      toDate: null,
      spoc_names: [],
      select1: "",
      select2: "",
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      dateRange: new Date(),
      duration: [
        { text: "WTD", value: 0 },
        { text: "MTD", value: 1 },
        { text: "QTD", value: 2 },
        { text: "YTD", value: 3 },
      ],
      sort_by: [
        { text: "MWB Count", value: 0 },
        { text: "Enrollment", value: 1 },
        { text: "Visitors", value: 2 },
        { text: "Utilization", value: 3 },
        { text: "Delays", value: 4 },
        { text: "Due", value: 5 },
        { text: "SR%", value: 6 },
      ],
      selected_city: "",
      loggedin: "",
      cities: [],
      tablerowConstant: [
        {
          style: {
            borderTop: "1px solid",
            borderBottom: "1px solid",
          },
          level: "",
        },
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid" },
          level: "corporate",
          type: "countable",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "eligibility",
          type: "countable",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "loans",
          type: "countable",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "net_enquiries",
          type: "countable",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "reference",
          type: "countable",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "university",
          type: "countable",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "visitors",
          type: "countable",
        },
        {
          style: {
            borderTop: "1px solid",
            borderBottom: "1px solid",
          },
          level: "Total",
          type: "total",
        },
      ],
      tableHeaders: [],
      tableRows: [],
      users: [
        {
          id: 1,
          name: "Ismail",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 2,
          name: "Nida",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 3,
          name: "Anita",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 4,
          name: "Imran",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 5,
          name: "Jain",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
      ],
      newdata: [
        {
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "",
          total: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Maria: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Imran: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Ismail: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
          Anita: {
            allocated: "A",
            contacted: "C",
            not_contacted: "NC",
            percentage: "NC%",
          },
        },
        {
          color: "#FFCCCC",
          overall: {
            allocated: "",
            contacted: "Total",
            not_contacted: "",
          },
          level: "",
          total: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Maria: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Imran: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
        },
        {
          color: "#CCFFCC",
          overall: "",
          level: "M6",
          total: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Maria: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Imran: {
            allocated: "0",
            contacted: "0",
            not_contacted: "0",
            percentage: "0",
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
        },
        {
          color: "#CCFFCC",
          overall: {
            allocated: "",
            contacted: "P1",
            not_contacted: "",
          },
          level: "M5",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: "0",
          },
        },
        {
          color: "#CCFFCC",
          overall: "",
          level: "M3+",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCFFCC",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "M3",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: "",
          level: "L2",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "L3",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: {
            allocated: "",
            contacted: "P2",
            not_contacted: "",
          },
          level: "L3+",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: "",
          level: "L4",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: "",
          level: "L5",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#FFFFCC",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "L6",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCFFFF",
          overall: {
            allocated: "",
            contacted: "P3",
            not_contacted: "",
          },
          level: "L4-",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCFFFF",
          overall: "",
          level: "L1",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: {
            allocated: "",
            contacted: "",
            not_contacted: "",
          },
          level: "M1",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: {
            allocated: "",
            contacted: "P4",
            not_contacted: "",
          },
          level: "M2",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: "",
          level: "M4-",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#efd6f7cf",
          overall: "",
          level: "M4",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
        {
          color: "#CCCCCC",
          overall: {
            allocated: "",
            contacted: "N/A",
            not_contacted: "",
          },
          level: "",
          total: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Maria: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Imran: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Ismail: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
          Anita: {
            allocated: 0,
            contacted: 0,
            not_contacted: 0,
            percentage: 0,
          },
        },
      ],
    };
  },
  mounted() {
    this.getSpocs();
    EventBus.$on("updateCoverage", () => {
      this.updateValues();
    });
  },
  watch: {
    selected_city: function () {
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        this.updateValues();
      }
    },
    dateRange() {
      this.updateValues();
    },
  },
  methods: {
    preFlightFetch(level, placement, type) {
      this.popupString = `${this.getName(type)} OF ${this.getName(level)}`;
      if (type === "Allocated" || type === "Delays" || type === "MHP") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        this.fetchSelectedData(level, spocname, newtype);
      } else {
        this.fetchSelectedData(level, placement, type);
      }
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 3;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: [],
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 3) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 1) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
    convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    getstyle(data) {
      if (
        data.overall.contacted === "Total" ||
        data.level === "M3" ||
        data.level === "L6" ||
        data.level === "L1" ||
        data.level === "M4" ||
        data.overall.contacted === "N/A"
      ) {
        return "1px solid";
      } else {
        return "";
      }
    },
    fetchSelectedData(level, placement, type) {
      this.spoc_names.forEach((spoc) => {
        if (spoc.full_name === placement) {
          placement = spoc.id;
        }
      });
      if (this.dateRange === "") {
        this.$vs.notify({
          title: "Select a Date",
          text: "Select a date to continue using this feature",
          color: "warning",
        });
      } else {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getDelaysByMHPContactDetails`;
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          mhp: level,
          type: type,
          spoc_id: "",
          city: "",
        };
        if (Number.isInteger(placement)) {
          obj.spoc_id = placement;
        } else {
          obj.city = placement;
        }
        if (obj.mhp === "Total") {
          obj.mhp = "total";
        }
        // console.log(obj);
        axios
          .post(url, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log(response);
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            this.$vs.loading.close();
            EventBus.$emit("open-coverage-details", [
              obj,
              url,
              response.data,
              "getDelaysByMHPContactDetails",
              this.popupString,
              response.data.total,
            ]);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getSpocList(city) {
      if (city == 'Pan India') {
        let selected_spocs = [];
        this.spoc_names.forEach((spoc) => {
            selected_spocs.push(spoc.id);
        });
        selected_spocs = selected_spocs.join();
        return selected_spocs;
      }else{
      let selected_spocs = [];
      this.spoc_names.forEach((spoc) => {
        if (spoc.city === city) {
          selected_spocs.push(spoc.id);
        }
      });
      selected_spocs = selected_spocs.join();
      return selected_spocs;
      }
    },
    updateValues() {
      /* let trialarr = [
        {
          spoc_id: 54,
          spoc_name: "Maria",
          corporate: {
            allocated: 93,
            delays: 68
          },
          eligibility: {
            allocated: 93,
            delays: 68
          },
          loans: {
            allocated: 93,
            delays: 68
          },
          net_enquiries: {
            allocated: 93,
            delays: 68
          },
          reference: {
            allocated: 93,
            delays: 68
          },
          university: {
            allocated: 93,
            delays: 68
          },
          visitors: {
            allocated: 93,
            delays: 68
          },
          NA: {
            allocated: 93,
            delays: 68
          }
        },
        {
          spoc_id: 75,
          spoc_name: "Imran",
          corporate: {
            allocated: 45,
            delays: 345
          },
          eligibility: {
            allocated: 678,
            delays: 234
          },
          loans: {
            allocated: 56,
            delays: 34
          },
          net_enquiries: {
            allocated: 234,
            delays: 123
          },
          reference: {
            allocated: 890,
            delays: 56
          },
          university: {
            allocated: 236,
            delays: 855
          },
          visitors: {
            allocated: 963,
            delays: 648
          },
          NA: {
            allocated: 7393,
            delays: 368
          }
        },
        {
          spoc_id: 105,
          spoc_name: "Anita",
          corporate: {
            allocated: 93,
            delays: 68
          },
          eligibility: {
            allocated: 93,
            delays: 68
          },
          loans: {
            allocated: 93,
            delays: 68
          },
          net_enquiries: {
            allocated: 93,
            delays: 68
          },
          reference: {
            allocated: 93,
            delays: 68
          },
          university: {
            allocated: 93,
            delays: 68
          },
          visitors: {
            allocated: 93,
            delays: 68
          },
          NA: {
            allocated: 93,
            delays: 68
          }
        },
        {
          spoc_id: 76,
          spoc_name: "Ismail",
          corporate: {
            allocated: 93,
            delays: 68
          },
          eligibility: {
            allocated: 93,
            delays: 68
          },
          loans: {
            allocated: 93,
            delays: 68
          },
          net_enquiries: {
            allocated: 93,
            delays: 68
          },
          reference: {
            allocated: 93,
            delays: 68
          },
          university: {
            allocated: 93,
            delays: 68
          },
          visitors: {
            allocated: 93,
            delays: 68
          },
          NA: {
            allocated: 93,
            delays: 68
          }
        }
      ];
      this.generateDataTable(trialarr); */
      if (this.selected_city === "") {
        this.$vs.notify({
          title: "Select a City",
          text: "Select a city to get coverage details",
          color: "warning",
        });
        return;
      }
      this.$vs.loading();
      let obj = {
        selected_date: this.datatoTimestamp(this.dateRange),
        spoc_ids: this.getSpocList(this.selected_city),
        pan_india: "",
      };
      if (this.selected_city == 'Pan India') {
        obj.pan_india = 'Yes';
      }
      axios
        .get(`${constants.SERVER_API}getDelaysByMHP`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.citySelection = this.selected_city;
          console.log(response);
          if (response.data.status === "error") {
            this.handleNotification(response);
            this.$vs.loading.close();
          } else {
            this.generateDataTable(response.data.data);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getName(name) {
      let newname = name.replace(/_/g, " ");
      return newname.toUpperCase();
    },
    generateDataTable(response) {
      this.tableRows = this.tablerowConstant;
      this.generateTableHeaders(response);
      this.generateTableRows(response);
    },
    generateTableHeaders(response) {
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
      });
      if(this.selected_city != 'Pan India'){
      let city = this.selected_city;
      this.tableHeaders.push({
        header: city,
        colspan: 3,
      });
    }else {
        this.tableHeaders.push({
          header: '',
          colspan: 3,
        });
      }
      if (this.selected_city == "Pan India") {
      response.forEach((cityData) => {
        let obj = {
          header: cityData.city,
          colspan: 3,
        };
        this.tableHeaders.push(obj);
      });
    }else{
      response.forEach((cityData) => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 3,
        };
        this.tableHeaders.push(obj);
      });
    }
      console.log(this.tableHeaders);
    },
    generateTableRows(response) {
      this.tableRows[0].dynamicSpoc = [];
      if(this.selected_city != 'Pan India'){
      this.tableRows[0].city = {
        allocated: "Allocated",
        delays: "Delays",
        mhp: "MHP",
      };
      }else{
        this.tableRows[0].city = {}
      }
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          if(this.selected_city != 'Pan India'){
          row.city = {
            allocated: 0,
            delays: 0,
            mhp: 0,
          };
        }
        }
      });
      response.forEach(() => {
        let allocated = 0;
        let delays = 0;
        let mhp = 0;
        this.tableRows[0].dynamicSpoc.push("Allocated");
        this.tableRows[0].dynamicSpoc.push("Delays");
        this.tableRows[0].dynamicSpoc.push("MHP");
        this.tableRows.forEach((row, index) => {
          if (index !== 0) {
            row.dynamicSpoc.push(allocated);
            row.dynamicSpoc.push(delays);
            row.dynamicSpoc.push(mhp);
          }
        });
      });
      // this.$vs.loading.close();
      this.InitialCalculation(response);
    },
    InitialCalculation(response) {
      this.tableRows.forEach((row, index) => {
        if (index > 0) {
          let cityallocated = 0;
          let citydelays = 0;
          let cityMHP = 0;
          row.dynamicSpoc = [];
          response.forEach((spoc) => {
            for (var key in spoc) {
              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  if (key === row.level) {
                    cityallocated = cityallocated + spoc[key].allocated;
                    citydelays = citydelays + spoc[key].delays;
                    cityMHP = cityMHP + spoc[key].missed_hot_pockets;
                    row.dynamicSpoc.push(spoc[key].allocated);
                    row.dynamicSpoc.push(spoc[key].delays);
                    row.dynamicSpoc.push(spoc[key].missed_hot_pockets);
                  }
                }
              }
            }
          });
          if(this.selected_city != 'Pan India'){
          row.city.allocated = cityallocated;
          row.city.delays = citydelays;
          row.city.mhp = cityMHP;
          }
        }
      });
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let totalcityMHPs = 0;
      let dynamicarr = [];
      this.tableRows.forEach((row, index) => {
        if (index > 0) {
          totalcityallocated = totalcityallocated + row.city.allocated;
          totalcitydelays = totalcitydelays + row.city.delays;
          totalcityMHPs = totalcityMHPs + row.city.cityMHP;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] = parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      // this.tableRows[1].city.allocated = totalcityallocated;
      // this.tableRows[1].city.delays = totalcitydelays;
      // this.tableRows[1].dynamicSpoc = dynamicarr;
      this.calculateTotals();
      this.$vs.loading.close();
    },
    calculateTotals() {
      console.log(this.tableRows);
      // let parts = ["P1", "P2", "P3"];
      // parts.forEach(eachPart => {
      let cityAllocated = 0;
      let cityDelays = 0;
      let cityMhp = 0;

      let dynamicSpocValues = [];
      this.tableRows[0].dynamicSpoc.forEach(() => {
        dynamicSpocValues.push(0);
      });
      this.tableRows.forEach((tableRow) => {
        if(this.selected_city != 'Pan India'){
        if (tableRow.type === "countable") {
          cityAllocated = parseInt(cityAllocated) + parseInt(tableRow.city.allocated);
          cityDelays = parseInt(cityDelays) + parseInt(tableRow.city.delays);
          cityMhp = parseInt(cityMhp) + parseInt(tableRow.city.mhp);

          tableRow.dynamicSpoc.forEach((value, index) => {
            dynamicSpocValues[index] =
              parseInt(dynamicSpocValues[index]) + parseInt(value);
          });
        } else if (tableRow.type === `total`) {
          tableRow.city.allocated = cityAllocated;
          tableRow.city.delays = cityDelays;
          tableRow.dynamicSpoc = dynamicSpocValues;
          tableRow.city.mhp = cityMhp;
        }
      }else{
            tableRow.city = {}
            if (tableRow.type === "countable") {
              tableRow.dynamicSpoc.forEach((value, index) => {
                dynamicSpocValues[index] =
                  parseInt(dynamicSpocValues[index]) + parseInt(value);
              });
            }
            if (tableRow.type === `total`) {
              tableRow.dynamicSpoc = dynamicSpocValues;
            }
          }
      });
      // });
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    getSpocs() {
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;

      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "cm4"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          this.extractSpocByCity(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getrole(providedrole) {
      if (localStorage.getItem("role") === providedrole) {
        return true;
      } else {
        return false;
      }
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = ["Pan India"].concat(this.sortArrayAlphabetically(this.cities));
      // this.cities = this.sortArrayAlphabetically(this.cities);
      if (localStorage.getItem("role") === "cm4") {
        this.selected_city = this.cities[1];
      } else if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "exed"
      ) {
        this.spoc_names.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            console.log("assigning the city");
            this.selected_city = spoc.city;
          }
        });
      }
      this.updateValues();
    },
  },
  components: {
    DateRangePicker,
    Datepicker,
    VueApexCharts,
    "v-select": vSelect,
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
</style>
